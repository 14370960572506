import {
  AdMob,
  BannerAdSize,
  BannerAdPosition,
  BannerAdPluginEvents,
  InterstitialAdPluginEvents,
} from "@capacitor-community/admob";
import { isPlatform } from "@ionic/vue";

export async function admobInit(tracking = true) {
  // const { status } = await AdMob.trackingAuthorizationStatus();
  AdMob.initialize({
    requestTrackingAuthorization: tracking,
    // testingDevices: ["2077ef9a63d2b398840261c8221a0c9b"],
  });
}
export async function displayADBanner() {
  const adId = {
    ios: process.env.VUE_APP_IOS_ADUNIT,
    android: process.env.VUE_APP_ANDROID_ADUNIT,
  };
  const platformAdId = isPlatform("android") ? adId.android : adId.ios;
  const options = {
    adId: platformAdId,
    adSize: BannerAdSize.ADAPTIVE_BANNER,
    position: BannerAdPosition.BOTTOM_CENTER,
    margin: 0,
    // isTesting: true,
    // npa: true
  };

  AdMob.addListener(BannerAdPluginEvents.SizeChanged, (info) => {
    const appMargin = parseInt(info.height, 10);
    if (appMargin > 0) {
      document.querySelector(".ion-page").style.marginBottom = appMargin + "px";
    }
  });

  const result = await AdMob.showBanner(options).catch((e) => console.error(e));
  if (result === undefined) {
    console.log("show banner result undefined");
  }
}
export async function displayInterstitalBanner() {
  setTimeout(async () => {
    try {
      try {
        AdMob.addListener(InterstitialAdPluginEvents.Loaded, () => {
          // Subscribe prepared interstitial
        });
      } catch (listenerError) {
        console.error('Error in addListener:', listenerError);
      }
      
      const interstitialAdId = {
        ios: process.env.VUE_APP_IOS_INTER_ADUNIT,
        android: process.env.VUE_APP_ANDROID_INTER_ADUNIT,
      };

      const platformAdId = isPlatform("android") ? interstitialAdId.android : interstitialAdId.ios;


      const optionsInter = {
        adId: platformAdId,
        isTesting: false
      };
  
      AdMob.addListener(InterstitialAdPluginEvents.Loaded, async () => {
        try {
          await AdMob.showInterstitial();
        } catch (showInterstitialError) {
          console.error('Error in showInterstitial:', showInterstitialError);
        }
      });
  
      try {
        await AdMob.prepareInterstitial(optionsInter);
      } catch (prepareInterstitialError) {
        console.error('Error in prepareInterstitial:', prepareInterstitialError);
      }
      
    } catch (generalError) {
      console.error('General error in displayInterstitalBanner:', generalError);
    }
  }, 5000);
}
export async function removeADBanner() {
  await AdMob.removeBanner().catch((e) => console.log(e));
  document.querySelector(".ion-page").style.marginBottom = "0px";
}
