<template>
    <ion-header :translucent="true">
        <ion-toolbar color="tertiary">
            <ion-title class="ion-text-center"><img style="max-height: 45px" src="https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/umpirecheatsheet3 copy.png" alt=""></ion-title>
        </ion-toolbar>
    </ion-header>
</template>

<script>
import { IonHeader, IonToolbar, IonTitle } from '@ionic/vue';
export default  {
    name: 'PageHeader',
    components: { IonHeader, IonToolbar, IonTitle  },
}
</script>
