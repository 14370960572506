<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import {
  PushNotifications,
} from "@capacitor/push-notifications";
import {
  IonApp,
  IonRouterOutlet,
  isPlatform,
  alertController,
} from "@ionic/vue";
import { admobInit } from "@/helpers/adMob";

export default {
  name: "App",
  data() {
    return {
      isApp: false
    }
  },
  components: {
    IonApp,
    IonRouterOutlet,
  },
  async mounted() {
    // Check if the user has already been prompted for permissions
    const hasBeenPrompted = localStorage.getItem('hasBeenPromptedForPermissions');

    if(isPlatform('core') || isPlatform('mobileweb')) {
      this.isApp = false;
    } else {
      this.isApp = true;
    }

    // Only show the tracking permission alert if the user hasn't been prompted before
    if (!hasBeenPrompted && isPlatform("ios")) {
      const alert = await alertController.create({
        header: '"Umpire Cheat Sheet" would like permission to track you across apps and websites owned by other companies.',
        message: "This identifier will be used to deliver personalized ads to you.",
        buttons: [
          {
            text: "Allow Tracking",
            handler: () => {
              console.log("Allow Tracking");
              admobInit(true);
            },
          },
          {
            text: "Ask App Not to Track",
            handler: () => {
              console.log("Do Not Track");
              admobInit(false);
            },
          },
        ],
      });
      alert.present();
      // Mark that the user has been prompted so it doesn't happen again
      localStorage.setItem('hasBeenPromptedForPermissions', 'true');
    } else if (!hasBeenPrompted) {
      admobInit(true);
      // Mark that the user has been prompted for non-iOS platforms as well
      localStorage.setItem('hasBeenPromptedForPermissions', 'true');
    }

    // Push Notifications setup
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    if(isPushNotificationsAvailable && !hasBeenPrompted) { // Check if the user hasn't been prompted for push notifications
      PushNotifications.requestPermissions().then(async (result) => {
        console.log("request Permission", result);
        if (result.receive === "granted") {
          await PushNotifications.register();
        } else {
          // Handle permission denial
        }
      });

      PushNotifications.addListener("registration", () => {
        alert("Thank you for signing up for notifications! To get notified, use the star button on a league or team.");
      });

      PushNotifications.addListener("registrationError", () => {
        console.log("Error on registration!");
      });

      PushNotifications.addListener("pushNotificationReceived", () => {
        console.log("Notification received!");
      });

      PushNotifications.addListener("pushNotificationActionPerformed", () => {
        console.log("Pushed a notification!");
      });
    }
  },
};
</script>
