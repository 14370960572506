<template>
  
  <ion-page>
    <page-header></page-header>
    
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar color="tertiary" slot="bottom">
        <ion-tab-button v-for="(tab, index) of tabs" :tab="tab.tab" :key="index" :href="tab.href">
          <ion-icon :icon="tab.icon"></ion-icon>
          <ion-label>{{ tab.label }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { bookOutline, baseballOutline, notificationsCircleOutline, briefcaseOutline, hammerOutline } from 'ionicons/icons';

import PageHeader from '@/components/PageHeader.vue';

export default {
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, PageHeader },
  data() {
    return {
      tabs: [
        {
          tab: 'tab1',
          href: '/tabs/rulebooks',
          icon: bookOutline,
          label: 'Rulebooks'
        },
        {
          tab: 'tab5',
          href: '/tabs/assignments',
          icon: baseballOutline,
          label: 'Assignments'
        },
        {
          tab: 'tab3',
          href: '/tabs/announcements',
          icon: notificationsCircleOutline,
          label: 'Announcements'
        },
        {
          tab: 'tab2',
          href: '/tabs/tools',
          icon: hammerOutline,
          label: 'Tools'
        },
        {
          tab: 'tab4',
          href: '/tabs/settings',
          icon: briefcaseOutline,
          label: 'Settings'
        }
      ]
    }
  },

}
</script>